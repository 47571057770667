<template>
  <h3 v-if="title" class="ag-block__title">
    <a v-if="url" :href="url" target="_blank">{{ title }}</a>
    <span v-else>{{ title }}</span>
  </h3>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    url: {
      type: String
    }
  }
}
</script>
