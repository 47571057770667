<template>
  <p v-if="url && label" class="ag-block__cta">
    <a :href="url" class="ag-block__button">{{ label }}</a>
  </p>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    label: {
      type: String
    }
  }
}
</script>
