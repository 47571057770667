<template>
  <BaseNote id="simple3" :note="note" :selected="selected" v-on="$listeners" />
</template>

<script>
import BaseNote from "../../../components/Note/BaseNote.vue"

export default {
  components: {
    BaseNote
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.ag-block--note-simple3 {
  @apply relative overflow-hidden rounded-lg pl-40 ring-2 ring-inset ring-gray-200;

  .ag-block__featured-image {
    @apply absolute inset-y-0 left-0 w-40;

    img {
      @apply h-full w-full object-cover;
    }
  }

  .ag-block__body {
    @apply w-full p-8;
  }

  &.ag-block--has-featured-image {
    @apply flex;
  }
}
</style>
