<template>
  <BaseNote id="simple5" :note="note" :selected="selected" v-on="$listeners" />
</template>

<script>
import BaseNote from "../../../components/Note/BaseNote.vue"

export default {
  components: {
    BaseNote
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.ag-block--note-simple5 {
  @apply relative overflow-hidden rounded-lg pt-60;

  .ag-block__featured-image {
    @apply absolute inset-0;

    img {
      @apply h-full w-full object-cover;
    }
  }

  .ag-block__title,
  .ag-block__title a {
    @apply text-white;
  }

  .ag-block__body {
    @apply relative z-10 w-full bg-gradient-to-t from-black p-8 text-white;
  }
}
</style>
