<template>
  <div>
    <component :is="'style'">
      .ag-block--note.ag-block--note-simple2 { background-color: {{ accentColor }}; }
      .ag-block--note.ag-block--note-simple2 .ag-block__button { background-color: white; color: {{ accentColor }}; }
      .ag-block--note.ag-block--note-simple2 .ag-block__button:hover { background-color: rgba(255, 255, 255, 0.75);
      color: {{ brandColor }}; }</component
    >
    <BaseNote id="simple2" :note="note" :selected="selected" v-on="$listeners" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseNote from "../../../components/Note/BaseNote.vue"

export default {
  components: {
    BaseNote
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    },
    accentColor() {
      return this.note.accent_color || this.brandColor
    }
  }
}
</script>

<style>
.ag-block--note-simple2 {
  @apply overflow-hidden rounded-lg p-8;

  .ag-block__featured-image {
    @apply mr-8 flex-shrink-0;
  }

  .ag-block__title,
  .ag-block__title a {
    @apply text-white;
  }

  .ag-block__description {
    @apply text-white text-opacity-75;

    a {
      @apply font-medium text-white text-opacity-90;

      &:hover {
        @apply text-opacity-100;
      }
    }
  }

  .ag-block__body {
    @apply w-full;
  }

  &.ag-block--has-featured-image {
    @apply flex;
  }
}
</style>
