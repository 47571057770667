<template>
  <BaseNote id="simple" :note="note" :selected="selected" v-on="$listeners" />
</template>

<script>
import BaseNote from "../../../components/Note/BaseNote.vue"

export default {
  components: {
    BaseNote
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.ag-block--note-simple {
  .ag-block__featured-image {
    @apply mr-6 flex-shrink-0;
  }

  .ag-block__body {
    @apply w-full;
  }

  &.ag-block--has-featured-image {
    @apply flex;
  }
}
</style>
