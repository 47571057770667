<template>
  <span v-if="image" class="ag-block__featured-image">
    <img :src="image.full_url" :alt="title" width="100" />
  </span>
</template>

<script>
export default {
  props: {
    image: {
      type: [File, Object]
    },
    title: {
      type: String
    }
  }
}
</script>
