<template>
  <p v-if="synopsis" v-html="$markdown(synopsis)" class="ag-block__description"></p>
</template>

<script>
export default {
  props: {
    synopsis: {
      type: String
    }
  }
}
</script>
