<template>
  <div :class="['ag-block__note-wrapper', { 'ag-block--note-selected': selected }]">
    <span
      v-show="selected"
      class="ag-block__selected-indicator absolute right-3 top-3 z-50 rounded-md bg-secondary px-3 py-2 text-xs font-bold tracking-wider text-white"
      >SELECTED</span
    >
    <component :is="'style'">
      .ag-block--note .ag-block__button { background-color: {{ brandColor }}; } .ag-block--note .ag-block__button:hover
      { background-color: {{ brandHoverColor }}; }</component
    >
    <article
      class="ag-block ag-block--note"
      :class="[`ag-block--note-${id}`, { 'ag-block--has-featured-image': featured_image }]"
      v-on="$listeners"
    >
      <slot :title="title" :featured_image="featured_image">
        <NoteFeaturedImage :image="featured_image" />
        <div class="ag-block__body">
          <NoteTitle :title="title" :url="url" />
          <NoteSynopsis :synopsis="synopsis" />
          <NoteCTA v-if="user.hasPermission('blocks.note_advanced')" :url="url" :label="call_to_action_text" />
        </div>
      </slot>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import NoteCTA from "../../components/Note/NoteCTA.vue"
import NoteFeaturedImage from "../../components/Note/NoteFeaturedImage.vue"
import NoteTitle from "../../components/Note/NoteTitle.vue"
import NoteSynopsis from "../../components/Note/NoteSynopsis.vue"

export default {
  components: {
    NoteCTA,
    NoteFeaturedImage,
    NoteTitle,
    NoteSynopsis
  },
  props: {
    id: {
      type: String,
      required: true
    },
    note: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    },
    brandHoverColor() {
      return this.user.get("partner.brand_hover_color")
    },
    noteIsEmpty() {
      return this.note.title !== "" ||
        this.note.synopsis !== "" ||
        this.note.featured_image ||
        this.note.content_url !== "" ||
        this.note.call_to_action_text !== "" ||
        (this.note.expires_at && this.note.expires_at !== "")
        ? false
        : true
    },
    title() {
      return this.note.title || this.note.synopsis ? this.note.title : "Note title (optional)"
    },
    synopsis() {
      return this.note.title || this.note.synopsis ? this.note.synopsis : "A detailed description"
    },
    featured_image() {
      return this.noteIsEmpty ? { full_url: require("@/assets/images/note-sample.jpg") } : this.note.featured_image
    },
    call_to_action_text() {
      return this.noteIsEmpty ? "Click here" : this.note.call_to_action_text
    },
    url() {
      return this.noteIsEmpty ? "#" : this.note.content_url
    }
  }
}
</script>

<style>
.ag-block__note-wrapper {
  @apply relative;
}

.ag-block--note {
  .ag-block__description {
    @apply mb-0 mt-2;
  }

  .ag-block__cta {
    @apply mt-5;
  }

  .ag-block__button {
    @apply inline-block rounded-full bg-primary px-6 py-3 text-sm font-semibold text-white shadow-3d;
    @apply hover:bg-primary-hover hover:text-white;

    &__append {
      @apply ml-3;
    }

    &__prepend {
      @apply mr-3;
    }
  }
}
</style>
